import axios from "../../../services/Api";

export default {
  methods: {
    // async authenticate() {
    //   window.addEventListener('message', async function(event) {
    //     if (event?.data?.type === 'omniwallet_auth') {
    //       localStorage.setItem("omniwalletTemporaryToken", event.data.data)
    //       this.signIn();
    //     }
    //   }.bind(this));
    // },
    // async fireAuthReadyEvent() {
    //   window.parent.postMessage({
    //     type: "omniwallet_auth_ready",
    //   }, '*');
    // },
    async authenticateWithQueryParam() {
      let authParam = decodeURI(window.location.hash);
      authParam = authParam.replace('#/auth','');
      authParam = authParam.replace('?','');

      if (authParam.startsWith("t=")) {
        authParam = authParam.replace('t=','');
        const axiosConfig = {
          headers: {
            Authorization: `Bearer ${authParam}`
          }
        }

        try {
          await axios.get("/sanctum/csrf-cookie");
          await axios.post("/front-catalog/token-login", null, axiosConfig).then(() => {
            this.signIn();
          });
        } catch (error) {
          // Retry authentication if it fails
          this.authenticateWithQueryParam();
        }
      }
    }
  },
};
