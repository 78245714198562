<template>
  <div class="container-login">
    <div class="container-white">
      <div class="q-mt-xl">
        <img class="q-ma-lg img-login"  :src="settings.logo ? settings.logo : defaultImageApp"/>
        <div class="items-center text-center">
          <h5 class="q-mb-lg q-mt-xl">{{$t("Login.starting-session")}}</h5>
          <q-spinner
            class="q-mt-lg q-mb-xl"
            size="3rem"
            :thickness="5"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
import { ref } from "vue";
import { mapGetters, mapActions } from "vuex";
import TokenLoginMixin from "../mixins/TokenLoginMixin";
import settings from "@/mixins/settings";

export default {
  mixins: [TokenLoginMixin, settings],
  name: "AuthKeyLogin",
  components: {
    
  },
  data() {
    return {
      loading: false,
    };
  },
  setup() {

    return {
      
    };
  },
  computed: {
    ...mapGetters({
      authenticated: "auth/authenticated",
      user: "auth/user",
    }),
  },
  methods: {
    ...mapActions({ signIn: "auth/login" }),
  },
  mounted() {
    this.authenticateWithQueryParam();
  },
};
</script>

<style lang="scss" scoped>
.img-login {
  width: 150px;
}

h4 {
  font-weight: 700;
  font-size: 32px;
}

.container-login {
  background-color: var(--bg-color-primary);
  height: 110vh;
  .container-white {
    background-color: var(--bg-color-secondary);
    border-radius: 0px 0px 42% 42%;
    box-shadow: 0px 8px 16px 0px #00000033;
    border-bottom-left-radius: 50% 60% !important;
    border-bottom-right-radius: 50% 60% !important;
    padding-bottom: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    margin-left: 25%;
    margin-right: 25%;
    h4 {
      font-size: 28px;
    }
  }

  @media only screen and (min-width: 767px) {
    .text-subtitle2,
    .q-input {
      padding-left: 13%;
      padding-right: 13%;
    }
  }

  @media only screen and (max-width: 767px) {
    .container-white {
      margin-left: 0%;
      margin-right: 0%;
      border-bottom-left-radius: 50% 38% !important;
      border-bottom-right-radius: 50% 38% !important;
    }

    
  }

  @media (min-width: 768px) and (max-width: 820px) {
    .container-white {
      margin-left: 10%;
      margin-right: 10%;
      border-radius: 0px 0px 40% 40%;
      .items-center {
        padding: 25px 10px;
      }
    }
    .q-form {
      margin-right: 17%;
      margin-left: 17%;
    }
  }

  @media (min-width: 820px) and (max-width: 1020px) {
    .container-white {
      margin-left: 20%;
      margin-right: 20%;
      border-radius: 0px 0px 40% 40%;
      .items-center {
        padding: 25px 10px;
      }
    }
  }
}
</style>
